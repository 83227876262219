<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          src="@/assets/images/logo/logoHorizontal.svg"
          alt="logo"
          class="logoTop"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Rentabilidad Distribuida por Centros de Costo
          </b-card-title>
          <b-card-text class="mb-2">
            Ingrese sus credenciales para acceder a la plataforma
          </b-card-text>

          <b-alert
            variant="primary"
            show
          >
            <div
              v-if="errorMessage"
              class="alert-body font-small-2"
            >
              <p>
                <small class="mr-50">
                  <span class="font-weight-bold">{{ errorMessage }}</span>
                </small>
              </p>
            </div>
          </b-alert>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login(userEmail, password)"
            >
              <!-- email -->
              <b-form-group
                label="Usuario"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="user"
                  vid="text"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="Coloca tu usuario"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label>Contraseña</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="rememberMe"
                  name="checkbox-1"
                >
                  Recordarme
                </b-form-checkbox>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                <span v-if="!loading">Ingresar</span>
                <span v-if="loading">Cargando...</span>
              </b-button>

              <p class="mt-2 text-right text-gray">
                Versión: {{ version }}
              </p>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import {required} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import {abilities} from '@/libs/acl/config'

import environment from '@/environment'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      version: environment.version,
      errorMessage: '',
      loading: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      rememberMe: false,

      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.getRememberUser()
  },
  methods: {
    async login(username, password) {
      try {
        this.errorMessage = ''
        this.loading = true
        await this.$refs.loginForm.validate()

        const {data} = await useJwt.login({username, password})
        const {token} = data

        useJwt.setToken(token)
        useJwt.setRefreshToken(data.refreshToken)
        const ability = [{
          action: 'manage',
          subject: 'auth',
        }, {
            action: 'manage',
            subject: 'dashboard',
        }]

        data.access.forEach(module => {
          if (module.read) {
            ability.push({
              action: 'read',
              subject: module.subject,
            })
          }
          if (module.update) {
            ability.push({
              action: 'write',
              subject: module.subject,
            })
          }
          if (module.create) {
            ability.push({
              action: 'write',
              subject: module.subject,
            })
          }
          if (module.delete) {
            ability.push({
              action: 'delete',
              subject: module.subject,
            })
          }
        })

        const userData = {
          ...data, ability
        }
        delete userData.token
        delete userData.access

        localStorage.setItem('userData', JSON.stringify(userData))
        localStorage.setItem('rememberMe', this.rememberMe)

        await this.$store.dispatch('catalog/fetchCatalogs')

        this.$ability.update(userData.ability)

        this.$router.replace('/')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Bienvenido ${userData.fullName || userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'Info',
                  text: `Has ingresado exitosamente como ${userData.role}.`,
                },
              })
            })

        this.loading = false
      } catch (err) {
        console.error(err)
        this.loading = false
        this.errorMessage = 'Valida los datos ingresados!!'
      }
    },
    getRememberUser(){
      if (localStorage.getItem('userData') && localStorage.getItem('rememberMe') === 'true') {
        this.userEmail = JSON.parse(localStorage.getItem('userData')).username
        this.rememberMe = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
